<template>
  <div class="login">
    <h2>欢迎，</h2>
    <div class="username">
      <p>填写手机或邮箱</p>
      <div class="input-box">
        <input type="text" placeholder="请输入邮箱" v-model="PhoneOrEmail" @focus='showKeyboard=false'>
        <button @click="getCode" v-show="showbtn">获取验证码</button>
        <p v-show="!showbtn">已发送（{{time}}）</p>
      </div>
    </div>
    <div class="captch">
      <div class="input-box">
        <input type="number" placeholder="请输入图形验证码" v-model="codekey" @focus='showKeyboard=false'>
        <img :src="codeImg" class="code-img" @click="getCaptch">
      </div>
    </div>
    <div class="code-box" @click="showKeyboard = true; isloading=false; value =''">
      <p>输入验证码</p>
      <div class="input-code">
        <van-password-input :value="value" :mask="false" :gutter="5" :focused="showKeyboard"
          @focus="showKeyboard = false" />
        <div class="loading-code" v-show="isloading">
          <van-icon name="clear" size="16" />
          <p>验证码有误，请重试</p>
        </div>
      </div>
    </div>
    <van-checkbox v-model="checked" v-if="showEmpower">绑定此微信号</van-checkbox>
    <van-button round block :loading='isloading' @click="login"
      color="linear-gradient(317deg, #b8262e 0%, #f26a52 100%)">
      确认登录
    </van-button>
    <p class="register" v-if="showEmpower" @click="$router.push({path:'/register'})">立即注册</p>
    <!-- <van-button round block :loading='isloading' @click="empower"
      color="linear-gradient(317deg, #b8262e 0%, #f26a52 100%)">
      微信授权登录
    </van-button> -->
    <van-number-keyboard :show="showKeyboard" v-model="value" @input="onInput" @delete="onDelete" />
  </div>
</template>



<script>
export default {
  data() {
    return {
      value: "",
      showKeyboard: false,
      showbtn: true,
      interval: null,
      time: 60,
      isloading: false,
      PhoneOrEmail: "", // 手机号或者是邮箱
      codekey: "",
      codeImg: "",
      key: "",
      openid: "",
      checked: false,
      showEmpower: false,
    };
  },
  watch: {
    value(value) {
      if (value.length === 6) {
        this.showKeyboard = false;
        this.login();
      }
    },
  },
  created() {
    if (this.common.is_weixin()) {
      this.openid = localStorage.getItem("openid");
      this.showEmpower = true;
    } else {
      this.openid = "";
      this.showEmpower = false;
    }
    // if (this.common.is_weixin()) {
    //   let code = this.getQueryValue("code");
    //   if (code) {
    //     this.getWeixin(code);
    //   }
    // }
    this.getCaptch();
  },
  methods: {
    onInput(value) {},
    onDelete() {},
    async getWeixin(code) {
      const res = await this.axios.post("wechat/user", {
        code,
      });
      if (res.code === 200) {
        this.openid = res.data.raw.openid;
        localStorage.setItem("openid", res.data.raw.openid);
      } else {
        this.$toast(res.message);
      }
    },
    empower() {
      this.common.getCode();
    },
    async getCode() {
      if (this.time < 60) return;
      if (this.codekey.length <= 0) {
        this.$toast("请输入图形验证码");
        return;
      }
      if (
        this.checkMobile(this.PhoneOrEmail) ||
        this.checkEmail(this.PhoneOrEmail)
      ) {
        console.log("验证通过");
        if (
          this.checkMobile(this.PhoneOrEmail) &&
          !this.checkEmail(this.PhoneOrEmail)
        ) {
          const res = await this.axios.post("send_sms", {
            push: this.PhoneOrEmail,
            captch: this.codekey,
            key: this.key,
          });
          if (res.code === 200) {
            this.showbtn = false;
            clearInterval(this.interval);
            this.interval = setInterval(() => {
              if (this.time > 0) {
                this.time--;
              } else {
                clearInterval(this.interval);
                this.showbtn = true;
              }
            }, 1000);
          } else {
            this.$toast(res.message);
          }
        } else {
          // 邮箱
          console.log("邮箱验证码");
          const res = await this.axios.post("send_email", {
            push: this.PhoneOrEmail,
            captch: this.codekey,
            key: this.key,
          });
          if (res.code === 200) {
            this.showbtn = false;
            clearInterval(this.interval);
            this.interval = setInterval(() => {
              if (this.time > 0) {
                this.time--;
              } else {
                clearInterval(this.interval);
                this.showbtn = true;
              }
            }, 1000);
          } else {
            this.$toast(res.message);
          }
        }
      } else {
        console.log("验证失败");
        this.$toast("请输入正确的手机号或者邮箱");
      }
    },
    checkMobile(str) {
      let re = /^1\d{10}$/;
      if (re.test(str)) {
        return true;
      } else return false;
    },
    checkEmail(str) {
      let re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
      if (re.test(str)) {
        return true;
      } else return false;
    },
    async login() {
      const res = await this.axios.post("auth/login", {
        login_name: this.PhoneOrEmail,
        code: this.value,
        openid: this.checked === true ? this.openid : "",
      });
      console.log(res);
      if (res.code === 200) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        this.$router.push({ path: "/" });
      } else {
        this.$toast(res.message);
      }
    },
    async getCaptch() {
      const res = await this.axios.get("auth/captch");
      console.log(res);
      if (res.code === 200) {
        this.codeImg = res.data.captch.img;
        this.key = res.data.captch.key;
      }
    },
  },
};
</script>


<style lang="less" scoped>
.login {
  padding: 85px 50px 0 50px;
  h2 {
    font-size: 60px;
    margin-bottom: 93px;
  }
  .username {
    margin-bottom: 80px;
    p {
      font-size: 32px;
      margin-bottom: 35px;
    }
    .input-box {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 28px;
        line-height: 62px;
        color: #bdbdbd;
        margin: 0;
      }
      input {
        background: transparent;
        height: 62px;
        font-size: 36px;
        width: 70%;
      }
      ::-webkit-input-placeholder {
        /* WebKit browsers，webkit内核浏览器 */
        color: #a4a4a4;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      }
      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #a4a4a4;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      }
      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #a4a4a4;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #a4a4a4;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      }
      button {
        font-size: 28px;
        color: #b8262e;
        width: 174px;
        height: 62px;
        border-radius: 31px;
        border: 1px solid #b8262e;
        background: #f8f8f8;
      }
    }
  }
  .captch {
    margin-bottom: 40px;
    .input-box {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 28px;
        line-height: 62px;
        color: #bdbdbd;
        margin: 0;
      }
      .code-img {
        width: 250px;
      }
      input {
        background: transparent;
        height: 62px;
        font-size: 36px;
        width: 80%;
      }
      ::-webkit-input-placeholder {
        /* WebKit browsers，webkit内核浏览器 */
        color: #a4a4a4;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      }
      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #a4a4a4;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      }
      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #a4a4a4;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #a4a4a4;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      }
    }
  }
  .code-box {
    margin-bottom: 40px;
    position: relative;
    p {
      font-size: 32px;
      margin-bottom: 35px;
    }
    .input-code {
      .loading-code {
        width: 100%;
        height: 97px;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 28px;
        border-radius: 8px;
        position: absolute;
        top: 60px;
        left: 0;
        p {
          margin: 0;
          margin-left: 20px;
        }
      }
    }
    .van-password-input {
      padding: 0;
      margin: 0;
      .van-password-input__security {
        width: 40%;
      }
      .van-password-input__item {
        background: transparent;
        border: none;
        border-bottom: 1px solid #585858;
        font-size: 12px;
        height: 50%;
      }
      ::after {
        border: none;
      }
    }
  }
  .login-btn {
    width: 100%;
    height: 81px;
    background: linear-gradient(317deg, #b8262e 0%, #f26a52 100%);
    border-radius: 41px;
    font-size: 32px;
    color: #fff;
    margin-top: 60px;
    text-align: center;
    line-height: 81px;
  }
  .van-button {
    margin-top: 20px;
  }
  /deep/.van-checkbox__label {
    font-size: 15px;
  }
  .register {
    font-size: 30px;
    color: #f26a52;
    text-align: center;
    margin-top: 40px;
  }
}
</style>